import { Callback } from '../../../types';
import EventEmitter from 'events';
import { isMobile } from '../../../utils';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(false);
  const localTracksRef = useRef<LocalTrack[]>([]);
  const optionsRef = useRef(options);

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback(
    token => {
      setIsConnecting(true);
      return Video.connect(token, { ...optionsRef.current, tracks: [] }).then(
        newRoom => {
          setRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          newRoom.once('disconnected', () => {
            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(new EventEmitter() as Room));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          const videoDebugger = () => {
            // @ts-ignore
            Promise.resolve(window.twilioRoom.getStats()).then(stats => {
              // @ts-ignore
              stats.forEach(stat => {
                // @ts-ignore
                stat.remoteVideoTrackStats.forEach(
                  // @ts-ignore
                  (videoTrackStats: { trackId; codec; dimensions; frameRate; packetsLost }) => {
                    const { codec, dimensions, frameRate, packetsLost } = videoTrackStats;
                    // @ts-ignore
                    const text = `${codec}: / W: ${dimensions ? dimensions.width : ''} / L: ${packetsLost}`;
                    // @ts-ignore
                    console.log(videoTrackStats.trackId);
                    console.log(text);
                    // @ts-ignore
                    window.twilioRoom.participants.forEach(function(pp, i) {
                      if (pp._videoTracks.has(videoTrackStats.trackId)) {
                        const div = document.querySelector("[data-cy-participant='" + pp.identity + "'] > div");
                        if (div && div.querySelector('.stats')) {
                          // @ts-ignore
                          div.querySelector('.stats').innerText = text;
                        } else if (div) {
                          // @ts-ignore
                          div.insertAdjacentHTML('beforeend', "<div class='stats'>" + text + '</div>');
                        }
                      }
                    });
                  }
                );
              });
            });
          };

          /* setInterval(videoDebugger, 2000); */

          localTracksRef.current.forEach(track =>
            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
            // However, tracks must be published manually in order to set the priority on them.
            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            newRoom.localParticipant.publishTrack(track, { priority: track.kind === 'video' ? 'low' : 'standard' })
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }
        },
        error => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [onError]
  );

  return { room, isConnecting, connect };
}
