import { useEffect, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import { RemoteTrack, DataTrack, LocalDataTrack, LocalAudioTrack } from 'twilio-video';
// import { LocalDataTrack } from 'twilio-video';

export default function useRemoteMuting() {
  const { room, localTracks } = useVideoContext();
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const [instructorTrack, setInstructorTrack] = useState<LocalDataTrack | null>(null);

  useEffect(() => {
    if (/^instructor_/.test(room.localParticipant.identity)) {
      const dataTrack = new LocalDataTrack();
      setInstructorTrack(dataTrack);
      if (dataTrack) {
        room.localParticipant.publishTrack(dataTrack);
      }
    }
  }, [room.localParticipant]);

  useEffect(() => {
    if (room.state === 'connected') {
      room.on('trackSubscribed', function(track: RemoteTrack) {
        track.on('message', function(message: string) {
          const command: { identity: string; audio: boolean } = JSON.parse(message);
          if (command.identity === room.localParticipant.identity) {
            command.audio ? audioTrack.enable() : audioTrack.disable();
          }
          // @ts-ignore
          const muting_map_string: string = window.sessionStorage.getItem('muting_map') ?? '[]';

          const muting_map: { identity: string; audio: boolean }[] = JSON.parse(muting_map_string);
          let new_muting_map: { identity: string; audio: boolean }[] = muting_map.filter(
            map => map.identity !== command.identity
          );

          new_muting_map.push(command);

          window.sessionStorage.setItem('muting_map', JSON.stringify(new_muting_map));
        });
      });
    }
  }, [instructorTrack]);

  return instructorTrack;
}
