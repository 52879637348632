import React from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useRemoteMuting from '../../hooks/useRemoteMuting/useRemoteMuting';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';

const Container = styled('aside')(({ theme }) => ({
  padding: '0.5em',
  overflowY: 'auto',
  [theme.breakpoints.down('xs')]: {
    overflowY: 'initial',
    overflowX: 'auto',
    padding: 0,
    display: 'flex',
  },
}));

const ScrollContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
  },
}));

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const instructorTrack = useRemoteMuting();
  const students = participants.filter(participant => participant.identity.match(/^user_/));
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const instructorTest = RegExp(/^instructor_/);
  const showLocalParticipantTest = RegExp(/^(instructor_|superuser)/);

  return (
    <Container>
      <ScrollContainer>
        {!showLocalParticipantTest.test(localParticipant.identity) && (
          <Participant
            participant={localParticipant}
            isSelected={selectedParticipant === localParticipant}
            instructorTrack={null}
          />
        )}
        {students.map(participant => (
          <Participant
            key={participant.sid}
            participant={participant}
            isSelected={selectedParticipant === participant}
            instructorTrack={instructorTrack}
          />
        ))}
      </ScrollContainer>
    </Container>
  );
}
