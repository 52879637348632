import { useEffect, useState } from 'react';
import { Participant } from 'twilio-video';
import useVideoContext from '../useVideoContext/useVideoContext';

type NameMapProps = {
  identity: string;
  name: string | null;
};

export default function useDisplayName(participant: Participant) {
  const { room } = useVideoContext();
  const [participants, setParticipants] = useState(Array.from(room.participants.values()));
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    console.log('called');
    fetch(`/backstage/names/${room.name}`)
      .then(resp => resp.json())
      .then(json => {
        const parsedMap: NameMapProps[] = json;
        const [display] = parsedMap.filter(map => map.identity === participant.identity);
        if (display) {
          setDisplayName(display['name'] ? display['name'] : '');
        }
      });
  }, [participants]);

  return displayName;
}
