import useVideoContext from '../useVideoContext/useVideoContext';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useParticipants from '../useParticipants/useParticipants';
import useScreenShareParticipant from '../useScreenShareParticipant/useScreenShareParticipant';
import useSelectedParticipant from '../../components/VideoProvider/useSelectedParticipant/useSelectedParticipant';

export default function useMainSpeaker() {
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();
  const [instructor] = participants.filter(participant => participant.identity.match(/^instructor_/));
  const {
    room: { localParticipant },
  } = useVideoContext();

  const instructorTest = RegExp(/^instructor_/);

  if (instructorTest.test(localParticipant.identity)) {
    return localParticipant;
  } else {
    return instructor || localParticipant;
  }

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  // return instructor || selectedParticipant || screenShareParticipant || participants[0] || localParticipant;
}
