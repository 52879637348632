import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RoomType } from '../../types';

export function getPasscode() {
  const params = new window.URLSearchParams(window.location.search);
  const passcode = params.get('passcode') ?? '';
  return passcode;
}

export function getRoomName() {
  const params = new window.URLSearchParams(window.location.search);
  const roomName = params.get('roomName') ?? '';
  return roomName;
}

export function fetchToken(name: string, room: string, passcode: string, initial: boolean) {
  const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({ identity: name, roomName: room, passcode, initial }),
  });
}

export function verifyPasscode(passcode: string, roomName: string) {
  const params = new window.URLSearchParams(window.location.search);
  const identity: string = params.get('identity') ?? '';

  return fetchToken(identity, roomName, passcode, true).then(async res => {
    const jsonResponse = await res.json();
    if (res.status === 401) {
      return { isValid: false, error: jsonResponse.error?.message };
    }

    if (res.ok && jsonResponse.token) {
      return { isValid: true };
    }
  });
}

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function usePasscodeAuth() {
  const history = useHistory();

  const [user, setUser] = useState<{
    displayName: undefined;
    photoURL: undefined;
    passcode: string;
    roomName: string;
  } | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [roomType, setRoomType] = useState<RoomType>();

  const getToken = useCallback(
    (name: string) => {
      return fetchToken(name, user!.roomName, user!.passcode, false)
        .then(async res => {
          if (res.ok) {
            return res;
          }
          const json = await res.json();
          const errorMessage = getErrorMessage(json.error?.message || res.statusText);
          throw Error(errorMessage);
        })
        .then(res => res.json())
        .then(res => {
          setRoomType(res.room_type);
          return res.token as string;
        });
    },
    [user]
  );

  useEffect(() => {
    const passcode = getPasscode();
    const roomName = getRoomName();

    if (passcode) {
      verifyPasscode(passcode, roomName)
        .then(verification => {
          if (verification?.isValid) {
            setUser({ passcode, roomName } as any);
            history.replace(window.location.pathname);
          }
        })
        .then(() => setIsAuthReady(true));
    } else {
      setIsAuthReady(true);
    }
  }, [history]);

  const signIn = useCallback((passcode: string, roomName: string) => {
    return verifyPasscode(passcode, roomName).then(verification => {
      if (verification?.isValid) {
        setUser({ passcode, roomName } as any);
      } else {
        throw new Error(getErrorMessage(verification?.error));
      }
    });
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    return Promise.resolve();
  }, []);

  return { user, isAuthReady, getToken, signIn, signOut, roomType };
}
