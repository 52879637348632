import { LocalAudioTrack } from 'twilio-video';
import { useCallback } from 'react';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useLocalAudioToggle() {
  const { room, localTracks } = useVideoContext();
  const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      // not sure why I can't pass the room in from video, but it appears undefined
      // @ts-ignore
      const theRoom = window.twilioRoom;

      if (theRoom) {
        // @ts-ignore
        const muting_map_string: string = window.sessionStorage.getItem('muting_map') ?? '[]';

        const muting_map: { identity: string; audio: boolean }[] = JSON.parse(muting_map_string);

        const [participantCommand] = muting_map.filter(map => map.identity === theRoom.localParticipant.identity);

        if (!participantCommand || participantCommand.audio) {
          audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
        }
      } else {
        audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
      }
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled] as const;
}
