import React from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant, LocalDataTrack } from 'twilio-video';

interface ParticipantProps {
  participant: IParticipant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  isSelected: boolean;
  instructorTrack: LocalDataTrack | null;
}

export default function Participant({
  participant,
  disableAudio,
  enableScreenShare,
  instructorTrack,
  isSelected,
}: ParticipantProps) {
  const videoPriority = 'low';

  return (
    <ParticipantInfo participant={participant} isSelected={isSelected} instructorTrack={instructorTrack}>
      <ParticipantTracks
        participant={participant}
        disableAudio={disableAudio}
        enableScreenShare={enableScreenShare}
        videoPriority={videoPriority}
      />
    </ParticipantInfo>
  );
}
